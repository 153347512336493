<template>
<div>
    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de Normas</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarNorma)">
                            <b-row>

                                <b-col md="4">
                                    <validation-provider name="norma" :rules="{required: true}" v-slot="validationContext">
                                        <b-form-group label="Norma:" class="mb-2">
                                            <b-form-input type="text" v-model="datosNorma.nombre" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre"></b-form-input>
                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col md="12">
                                    <hr class="mb-3 mt-2">
                                    <span class="h5 text-muted"> Pestañas</span>
                                    <b-button size="sm" variant="dark" class="mb-2 float-right" @click="modalAgregarPestana = true">
                                        <i class="fas fa-plus fa-sm"></i> Agregar pestaña
                                    </b-button>
                                </b-col>
                                <b-col md="12">
                                    <div class="accordion" role="tablist" v-for="(pestana, k) in listaPestana.filter(x => x.estado == 2 )" :key="k">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                <div block v-b-toggle="`accordion1${k}`" class="bg-accordion">
                                                    <span class=" h6"> {{pestana.numero}} {{pestana.texto}}</span>
                                                    <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="`accordion1${k}`" :accordion="`my-accordion${k}`" role="tabpanel">
                                                <b-card-body>
                                                    <b-row>
                                                        <b-col md="2">
                                                            <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-group label="Número" class="mb-2">
                                                                    <b-form-input type="number" placeholder="Ingrese el número" :state="getValidationState(validationContext)" size="md" v-model="pestana.numero"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="8">
                                                            <validation-provider name="texto" :rules="{required: true}" v-slot="validationContext">
                                                                <b-form-group label="Texto" class="mb-2">
                                                                    <b-form-input type="text" placeholder="Ingrese el texto" :state="getValidationState(validationContext)" size="md" v-model="pestana.texto"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="2">
                                                            <b-button class="button-form" variant="danger" @click="eliminarPestana(k)" size="sm">
                                                                <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar pestaña
                                                            </b-button>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <hr class="mb-3 mt-2">
                                                            <span class="h5 text-muted"> Puntos</span>
                                                            <b-button size="sm" variant="dark" class="mb-2 float-right" @click="abrirModalAgregarPunto(k)">
                                                                <i class="fas fa-plus fa-sm"></i> Agregar punto
                                                            </b-button>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <div class="accordion" role="tablist" v-for="(punto, j) in listaPestana[k].puntos.filter(x =>x.estado ==2)" :key="j">
                                                                <b-card no-body class="mb-1">
                                                                    <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                                        <div block v-b-toggle="`accordion2${k}${j}`" class="bg-accordion">
                                                                            <span class="h6"> {{punto.numero}} {{punto.texto}}</span>
                                                                            <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                                        </div>
                                                                    </b-card-header>
                                                                    <b-collapse :id="`accordion2${k}${j}`" :accordion="`my-accordion2${k}${j}`" role="tabpanel">
                                                                        <b-card-body>
                                                                            <b-row>
                                                                                <b-col md="2">
                                                                                    <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Número" class="mb-2">
                                                                                            <b-form-input type="number" placeholder="Ingrese el número" :state="getValidationState(validationContext)" size="md" v-model="punto.numero"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="8">
                                                                                    <validation-provider name="texto" :rules="{required: true}" v-slot="validationContext">
                                                                                        <b-form-group label="Texto" class="mb-2">
                                                                                            <b-form-input type="text" placeholder="Ingrese el texto" :state="getValidationState(validationContext)" size="md" v-model="punto.texto"></b-form-input>
                                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                                        </b-form-group>
                                                                                    </validation-provider>
                                                                                </b-col>
                                                                                <b-col md="2">
                                                                                    <b-button class="button-form" variant="danger" @click="eliminarPunto(k,j)" size="sm">
                                                                                        <i class="fas fa-trash-alt  my-0 mx-0 mr-1"></i> Eliminar punto
                                                                                    </b-button>
                                                                                </b-col>
                                                                                <b-col md="12">
                                                                                    <hr class="mb-3 mt-2">
                                                                                    <span class="h5 text-muted"> Preguntas</span>
                                                                                    <b-button size="sm" variant="dark" class="mb-2 float-right" @click="abrirModalAgregarPregunta(k,j)">
                                                                                        <i class="fas fa-plus fa-sm"></i> Agregar pregunta
                                                                                    </b-button>
                                                                                </b-col>
                                                                                <b-col md="12" v-if="listaPestana[k].puntos[j].preguntas.length > 0">
                                                                                    <b-table bordered :items="listaPestana[k].puntos[j].preguntas.filter(x =>x.estado == 2)" :fields="campoPreguntas" class="table-custom mt-2" show-empty medium responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                                        <template #table-colgroup="cabeceraMedidas">
                                                                                            <col v-for="field in cabeceraMedidas.fields" :key="field.key" :style="{ width: field.key === 'numero' ? '15%' : 'texto' ? '70%' : '15%' }">
                                                                                        </template>
                                                                                        <template #cell(numero)="param">
                                                                                            <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                                                                                                <b-form-input type="number" placeholder="Ingrese el número" :state="getValidationState(validationContext)" size="md" v-model="param.item.numero"></b-form-input>
                                                                                            </validation-provider>
                                                                                        </template>
                                                                                        <template #cell(texto)="param">
                                                                                            <validation-provider name="texto" v-slot="validationContext">
                                                                                                <b-form-textarea v-model="param.item.texto" :state="getValidationState(validationContext)" placeholder="Ingrese el texto" rows="3" max-rows="6"></b-form-textarea>
                                                                                            </validation-provider>
                                                                                        </template>
                                                                                        <template #cell(opciones)="param">
                                                                                            <b-button @click="eliminarPregunta(k,j,param.index)" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                                                <i class="fas fa-trash my-0 mx-0"></i>
                                                                                            </b-button>
                                                                                        </template>
                                                                                    </b-table>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-card-body>
                                                                    </b-collapse>
                                                                </b-card>
                                                            </div>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-col>
                                <b-col class="my-2 text-center" md="12">
                                    <b-button :disabled="disabled" :to="{name:'Normas'}" size="md" class=" mr-2" variant="dark">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button v-if="$route.params.id && checkPermissions('010-MEJ-AUD-NOR','u')==1" :disabled="disabled" size="md" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                       <b-button v-if="!$route.params.id && checkPermissions('010-MEJ-AUD-NOR','c')==1"  :disabled="disabled" size="md" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalAgregarPestana">
        <template #header>

            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva pestaña </span>
            </h6>
            <CButtonClose @click="modalAgregarPestana = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(agregarPestana)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número" class="mb-2">
                                <b-form-input type="number" placeholder="Ingrese el número" :state="getValidationState(validationContext)" size="md" v-model="datosPestana.numero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="texto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Texto" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el texto" v-model="datosPestana.texto"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Agregar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalAgregarPestana = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalAgregarPunto">
        <template #header>

            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo punto </span>
            </h6>
            <CButtonClose @click="modalAgregarPunto = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(agregarPunto)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número" class="mb-2">
                                <b-form-input type="number" placeholder="Ingrese el número" :state="getValidationState(validationContext)" size="md" v-model="datosPunto.numero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col md="12">
                        <validation-provider name="pestaña" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Pestaña" class="mb-2">
                                <b-form-select v-model="datosPunto.numeroPestana" :state="getValidationState(validationContext)" :options="listaPestana" value-field="numero" text-field="numero">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col md="12">
                        <validation-provider name="texto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Texto" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el texto" size="md" v-model="datosPunto.texto"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Agregar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalAgregarPunto = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalAgregarPregunta">
        <template #header>

            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva pregunta </span>
            </h6>
            <CButtonClose @click="modalAgregarPregunta = false" class="text-white" />
        </template>

        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(agregarPregunta)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="número" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número" class="mb-2">
                                <b-form-input type="number" placeholder="Ingrese el número" :state="getValidationState(validationContext)" size="md" v-model="datosPregunta.numero"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col md="12">
                        <validation-provider name="pestaña" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Pestaña" class="mb-2">
                                <b-form-select v-model="datosPregunta.numeroPestana" :state="getValidationState(validationContext)" :options="listaPestana" value-field="numero" text-field="numero">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template> </b-form-select>

                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="punto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Punto" class="mb-2">
                                <b-form-select v-model="datosPregunta.numeroPunto" :state="getValidationState(validationContext)" :options="listaPestana[k]puntos.filter(x => x.numeroPestana == datosPregunta.numeroPestana)" value-field="numero" text-field="numero">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template> </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col md="12">
                        <validation-provider name="texto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Texto" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el texto" v-model="datosPregunta.texto"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Agregar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalAgregarPregunta = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,

            datosNorma: {
                idNorma: null,
                nombre: '',
                idCliente: '',
            },

            modalAgregarPestana: false,
            modalAgregarPunto: false,
            modalAgregarPregunta: false,
            campoPestana: [{
                key: "numero",
                label: "Número",
                class: "text-center",
            }, {
                key: "texto",
                label: "Texto",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],
            campoPuntos: [{
                key: "numero",
                label: "Número",
                class: "text-center",
            }, {
                key: "numeroPestana",
                label: "Número pestaña",
                class: "text-center",
            }, {
                key: "texto",
                label: "Texto",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],
            campoPreguntas: [{
                key: "numero",
                label: "Orden",
                class: "text-center cpn",
            }, {
                key: "texto",
                label: "Texto",
                class: "text-center cpt",

            }, {
                key: "opciones",
                label: "",
                class: "text-center cpo",

            }],

            listaPestana: [],

            //TEMPORALES
            listaPuntos: [],

            // listaPestana[k]puntos: [],
            // listaPreguntas: [],

            datosPestana: {
                numero: '',
                texto: ''
            },
            datosPunto: {
                numero: '',
                // numeroPestana: null,
                texto: ''
            },
            datosPregunta: {
                numero: '',
                // numeroPestana: null,
                // numeroPunto: null,
                texto: ''
            },
            indexPestana: '',
            indexPunto: '',
        }
    },
    methods: {
             checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        abrirModalAgregarPunto(k) {
            this.indexPestana = k;
            this.modalAgregarPunto = true;
        },
        abrirModalAgregarPregunta(k, j) {
            this.indexPestana = k;
            this.indexPunto = j;
            this.modalAgregarPregunta = true;
        },

        agregarPestana() {
            let me = this;
            me.listaPestana.push({
                numero: me.datosPestana.numero,
                texto: me.datosPestana.texto,
                estado: 2,
                puntos: []
            })
        },
        agregarPunto() {
            let me = this;
            me.listaPestana[me.indexPestana].puntos.push({
                numero: me.datosPunto.numero,
                texto: me.datosPunto.texto,
                estado: 2,
                preguntas: []
            })
        },
        agregarPregunta() {
            let me = this;
            me.listaPestana[me.indexPestana].puntos[me.indexPunto].preguntas.push({
                // idNormaPregunta: 0,
                numero: me.datosPregunta.numero,
                texto: me.datosPregunta.texto,
                estado: 2
            })
        },

        eliminarPestana(k) {
            let me = this;
            if (me.$route.params.id) {
                let lista = me.listaPestana.filter(x => x.estado == 2);
                if (lista[k].idNormaPestana) {
                    lista[k].estado = 1
                } else if (!lista[k].idNormaPestana) {
                    lista[k].estado = 0
                    for (let e in me.listaPestana) {
                        if (me.listaPestana[e].estado == 0) {
                            me.listaPestana.splice(e, 1);
                        }
                    }
                }
            } else {
                me.listaPestana.splice(k, 1)
            }
            // let me = this;
            // me.listaPestana.splice(k, 1)
        },
        eliminarPunto(k, j) {
            let me = this;
            if (me.$route.params.id) {
                let lista = me.listaPestana[k].puntos.filter(x => x.estado == 2);
                if (lista[j].idNormaPunto) {
                    lista[j].estado = 1
                } else if (!lista[j].idNormaPunto) {
                    lista[j].estado = 0
                    for (let e in me.listaPestana[k].puntos) {
                        if (me.listaPestana[k].puntos[e].estado == 0) {
                            me.listaPestana[k].puntos.splice(e, 1);
                        }
                    }
                }
            } else {
                me.listaPestana[k].puntos.splice(j, 1)
            }

            // let me = this;
            // me.listaPestana[k].puntos.splice(j, 1)
        },
        eliminarPregunta(k, j, v) {
            let me = this;
            if (me.$route.params.id) {
                let lista = me.listaPestana[k].puntos[j].preguntas.filter(x => x.estado == 2);
                if (lista[v].idNormaPregunta) {
                    lista[v].estado = 1
                } else if (!lista[v].idNormaPregunta) {
                    lista[v].estado = 0
                    for (let e in me.listaPestana[k].puntos[j].preguntas) {
                        if (me.listaPestana[k].puntos[j].preguntas[e].estado == 0) {
                            me.listaPestana[k].puntos[j].preguntas.splice(e, 1);
                        }
                    }
                }
            } else {
                me.listaPestana[k].puntos[j].preguntas.splice(v, 1)
            }

            // let me = this;
            // me.listaPestana[k].puntos[j].preguntas.splice(v, 1)
        },

        registrarNorma() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-norma", {
                        listaPestana: me.listaPestana,
                        datosNorma: me.datosNorma
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.disabled = false;
                    me.$router.push({
                        name: 'Normas'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });

        },
        obtenerNorma() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-norma", {
                        params: {
                            idCliente: me.datosNorma.idCliente,
                            idNorma: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosNorma.idNorma = response.data[0].idNorma;
                    me.datosNorma.nombre = response.data[0].nombre;
                    me.obtenerPestanas();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Normas'
                    });
                });
        },

        obtenerPestanas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-pestanas-norma", {
                        params: {
                            idNorma: me.datosNorma.idNorma
                        },
                    }
                )
                .then(function (response) {
                    for (let k in response.data) {
                        me.listaPestana.push({
                            idNormaPestana: response.data[k].idNormaPestana,
                            idNorma: response.data[k].idNorma,
                            numero: response.data[k].numero,
                            texto: response.data[k].texto,
                            estado: response.data[k].estado,
                            puntos: [],
                        })
                    }
                    me.obtenerPuntos();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Normas'
                    });
                });
        },

        obtenerPuntos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-puntos-norma", {
                        params: {
                            idNorma: me.datosNorma.idNorma
                        },
                    }
                )
                .then(function (response) {

                    // for (let j in response.data) {
                    //     me.listaPuntos.push({
                    //         idNormaPunto: response.data[j].idNormaPunto,
                    //         idNormaPestana: response.data[j].idNormaPestana,
                    //         numero: response.data[j].numero,
                    //         texto: response.data[j].texto,
                    //         estado: response.data[j].estado,
                    //         preguntas: []
                    //     })
                    // }
                    for (let k in me.listaPestana) {
                        for (let j in response.data) {
                            if (me.listaPestana[k].idNormaPestana == response.data[j].idNormaPestana) {
                                me.listaPestana[k].puntos.push({
                                    idNormaPunto: response.data[j].idNormaPunto,
                                    idNormaPestana: response.data[j].idNormaPestana,
                                    numero: response.data[j].numero,
                                    texto: response.data[j].texto,
                                    estado: response.data[j].estado,
                                    preguntas: []
                                })
                            }
                        }
                    }
                    me.obtenerPreguntas();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Normas'
                    });
                });
        },

        obtenerPreguntas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-preguntas-norma", {
                        params: {
                            idNorma: me.datosNorma.idNorma
                        },
                    }
                )
                .then(function (response) {
                    for (let k in me.listaPestana) {
                        for (let j in me.listaPestana[k].puntos) {
                            for (let v in response.data) {
                                if (me.listaPestana[k].puntos[j].idNormaPunto == response.data[v].idNormaPunto) {
                                    me.listaPestana[k].puntos[j].preguntas.push({
                                        idNormaPregunta: response.data[v].idNormaPregunta,
                                        idNormaPunto: response.data[v].idNormaPunto,
                                        numero: response.data[v].numero,
                                        texto: response.data[v].texto,
                                        estado: response.data[v].estado
                                    })
                                }
                            }
                        }
                    }
                    // for (let j in me.listaPuntos) {
                    //     for (let v in response.data) {
                    //         if (me.listaPuntos[j].idNormaPunto == response.data[v].idNormaPunto) {
                    //             me.listaPuntos[j].preguntas.push({
                    //                 idNormaPregunta: response.data[v].idNormaPregunta,
                    //                 idNormaPunto: response.data[v].idNormaPunto,
                    //                 numero: response.data[v].numero,
                    //                 texto: response.data[v].texto,
                    //                 estado: response.data[v].estado
                    //             })
                    //         }
                    //     }
                    // }

                    // for (let k in me.listaPestana) {
                    //     for (let j in me.listaPuntos) {
                    //         if (me.listaPestana[k].idNormaPestana == me.listaPuntos[j].idNormaPestana) {
                    //             me.listaPestana[k].puntos.push(me.listaPuntos[j])
                    //         }
                    //     }
                    // }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Normas'
                    });
                });
        },

        resetModalAgregarPestana() {
            this.datosPestana.numero = '';
            this.datosPestana.texto = '';
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        resetModalAgregarPunto() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosPunto.numero = '';
            this.datosPunto.texto = '';
        },
        resetModalAgregarPregunta() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosPregunta.numero = '';
            this.datosPregunta.texto = '';
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    watch: {
        modalAgregarPestana: function (val) {
            if (val == false) {
                this.resetModalAgregarPestana();
            }
        },
        modalAgregarPunto: function (val) {
            if (val == false) {
                this.resetModalAgregarPunto();
            }
        },
        modalAgregarPregunta: function (val) {
            if (val == false) {
                this.resetModalAgregarPregunta();
            }
        },
        'datosPregunta.numeroPestana': function (val) {
            if (val) {
                this.datosPregunta.numeroPunto = null
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosNorma.idCliente = user.uidClient;
            if (this.$route.params.id) {
                this.obtenerNorma();
            }
        }
    }

}
</script>

<style scoped>
.button-form {
    margin-top: 29px;
}
</style>
